@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

$font-family-base: 'Montserrat';


$h1-font-size: 68px;
$h2-font-size: 42px;
$h3-font-size: 26px;

//$font-size-base: 16px;


$font-sizes: (
  1: 22px,
  2: 20px,
  3: 18px,
  4: 16px,
  5: 14px,
  6: 12px,
);


@font-face {
  font-family: 'SF Compact Display Thin';
  font-style: normal;
  font-weight: normal;
  src: local('SF Compact Display Thin'), url('/assets/fonts/sf-compact-display-thin-58646eb43a785.woff') format('woff');
}


@font-face {
  font-family: 'SF Compact Display Medium';
  font-style: normal;
  font-weight: normal;
  src: local('SF Compact Display Medium'), url('/assets/fonts/sf-compact-display-medium-5864711817c30.woff') format('woff');
}
