.ng-select-container {
  border: 2px solid #737D80 !important;
  border-radius: 0.375rem !important;
  padding:6px 22px 6px 12px !important;
  height: 40px !important;
}

.is-invalid .ng-select-container {
  border: 2px solid #DC3545 !important;
  border-radius: 0.375rem !important;
}

.is-valid .ng-select-container {
  border: 2px solid $secondary !important;
  border-color: $secondary;
  background-image: url('../../assets/images/icons/circle-check.svg');
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  background-repeat: no-repeat;
}

.is-invalid .ng-select-container {
  border: 2px solid $danger !important;
  border-color: $danger;
  background-image: url('../../assets/images/icons/error.svg');
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  background-repeat: no-repeat;
}
