@import "definitions/colors";

.breadcrumb-item {
  font-size: 14px;
  color: $secondary-items;
}

.breadcrumb-item a {
  color: $secondary-items;
}

.breadcrumb-item.active > * {
  color: $secondary;
}
