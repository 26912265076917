@import "../definitions/colors";


.btn-primary:hover {
  background-color: $primary-hover !important;
}

.btn-secondary:hover {
  background-color: $secondary-hover !important;
}


.btn-primary:active {
  background-color: $primary-active !important;
}

.btn-secondary:active {
  background-color: $secondary-active !important;
}


.btn-primary, .btn-secondary {
  color: white !important;
}

.btn-outline-secondary {
  @include button-outline-variant($secondary, white, lighten($secondary, 5%), $secondary);
}

.btn-outline-primary-items {
  @include button-outline-variant($primary-items, white, lighten($secondary, 5%), $primary-items);
}

.btn-outline-primary-items:hover {
  color: white;
  background-color: $secondary-items;
}

.pagination-size {
  height: 28px;
  width: 28px;
  font-size: 14px;
  padding: 0;
}

.pagination-btn {
  @extend .pagination-size;
  @extend .btn-outline-primary-items;
}

.pagination-btn.active {
  color: white;
  @extend .btn-outline-secondary;
}

.pagination-btn:hover {
  background-color: $secondary;
  @extend .btn-outline-secondary;
}

.btn-close {
  font-size: 10px;
}

