/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import "../../node_modules/@ng-select/ng-select/themes/default.theme.css";

@import "definitions/index";

@import '../../node_modules/bootstrap/scss/bootstrap';
@import "../../node_modules/bootstrap-icons/font/bootstrap-icons.css";

@import "reset";
@import "components/index";
