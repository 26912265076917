// Default colors
$primary: #19315D;
$secondary: #4BA8F7;
$success: #28A745;
$danger: #DC3545;
$warning: #FFC107;
$info: #1cc8f0;
$light: #f8f9fa;
$dark: #212529;


//Custom colors
$primary-items: #9CA3A8;
$secondary-items: #737D80;
$primary-hover: #536A93;
$primary-active: #304976;
$secondary-active: #73BCF9;
$secondary-hover: #A3D3FB;

:root {
  --app-primary-items: #9CA3A8;
  --app-secondary-items: #737D80;
  --app-primary-hover: #536A93;
  --app-primary-active: #304976;
  --app-secondary-active: #73BCF9;
  --app-secondary-hover: #A3D3FB;
}

$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "warning":    $warning,
  "danger":     $danger,
  "info":       $info,
  "light":      $light,
  "dark":       $dark,
);

//Body
$body-bg: #F4F6F6;
$body-color: #4E4E4E;


//Inputs
$input-btn-focus-color: $secondary;
$input-border-color: $secondary-items;
$form-label-color: $secondary-items;
$input-bg: #ffff;


//Dropdowns
$dropdown-link-hover-color: white;
$dropdown-link-active-color: white;
$dropdown-link-hover-bg: $primary-hover;
$dropdown-link-active-bg: $primary;


//Navbar
$navbar-bg: $primary;
$navbar-light-color: white;
$navbar-light-hover-color: $secondary-hover;
$navbar-light-active-color: $secondary;


//Button
$btn-hover-color: white !important;

// Breadcum
$breadcrumb-active-color: $secondary;

// Tables
$table-striped-bg: $body-bg;
$table-bg: white;

