h1, h2, h3, h4, h5, h6, p {
  margin: 0 !important;
}

h1, .h1 {
  font-size: 68px;
  font-weight: normal;
  font-style: normal;
}

h2, .h2 {
  font-size: 42px;
  font-weight: normal;
  font-style: normal;
}

h3, .h3 {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
}
