@include color-mode(dark) {
  input, input:active, input:focus {
    color: $body-color;
  }

  ::placeholder{
    color: #B0B0B0;
  }
}

//Span complementary(search icon)
.input-span-complementary{
  border-color: $input-border-color;
  border-width: $input-border-width;
  background-color: $input-bg;
}
